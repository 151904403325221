import PropTypes from "prop-types";
import { RevTooltipSimple } from "./revTooltip";
import { DangerousDiv } from "../dangerousGerneric";

const integerNumberPastePattern = /^[0-9\b]{0,11}?$/;
const decimalNumberPastePattern = /^[0-9\b]{0,11}([,.])?([0-9]{0,2})?$/;
const numberFieldInputPatternWithSpecialActions =
  /tab|enter|escape|backspace|delete|arrowleft|arrowright|arrowup|arrowdown|home|end/i;
const decimalPattern = /[.,]{1}/;

const nextOnEnter = (e) => {
  if (e.key === "Enter") {
    const inputs = Array.from(document.querySelectorAll("input,select"));
    let index = inputs.indexOf(e.target) + 1;
    if (index === inputs.length) {
      index = 0;
    }
    inputs[index].focus();
  }
};

// https://bulma.io/documentation/form/input/
// extra class options:
// medium/large
// the on key down to prevent text input is horrible but necessary

const onKeyDown = (e) => {
  // meta key is the apple command key
  if (e.ctrlKey || e.altKey || e.metaKey) {
    return;
  }
  if (numberFieldInputPatternWithSpecialActions.test(e.key.toString())) {
    return;
  }
  const isNumberInput = /^[0-9]$/.test(e.key.toString());
  if (isNumberInput) {
    testMinMax(e, 0, 100000000000);
  } else {
    e.preventDefault();
  }
};

const onDecimalKeyDown = (e) => {
  const currentValue = e.target.value.toString();
  const inputKey = e.key.toString();

  if (e.ctrlKey || e.altKey) {
    return;
  }

  const isDecimalInput = decimalPattern.test(inputKey);

  const currentHasDecimal = decimalPattern.test(currentValue);
  if (currentHasDecimal && isDecimalInput) {
    e.preventDefault();
  }

  if (numberFieldInputPatternWithSpecialActions.test(inputKey)) {
    return;
  }

  const isNumberInput = /^[0-9]$/.test(inputKey);
  if (!isDecimalInput && !isNumberInput) {
    e.preventDefault();
  }

  if (decimalNumberPastePattern.test(e.key.toString())) {
    testMinMax(e, 0, 100000000000);
  }
};

// blocks pasting rubish in the input field
const onPaste = (e) => {
  const paste = (e.clipboardData || window.clipboardData).getData("text");
  if (!integerNumberPastePattern.test(paste)) {
    e.preventDefault();
  }
};

const onDecimalPaste = (e) => {
  const paste = (e.clipboardData || window.clipboardData).getData("text");
  if (!decimalNumberPastePattern.test(paste)) {
    e.preventDefault();
  }
};

const testMinMax = (e, min, max) => {
  const result = Number(e.target.value.toString() + e.key.toString());

  if (result > max || result < min) {
    e.preventDefault();
  }
};

export const NumberInput = ({
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value,
  error: errors,
  dataTarget,
  inputClass = "Normal",
  extraClass,
  addonText,
  prefixAddonText,
  readOnly,
  min = 0,
  max = 1000000000,
  step,
  noDecimals = false,
  simpleTooltipText,
}) => {
  // set bulma classes
  if (errors && errors.length > 0) {
    inputClass = "is-danger input";
  } else {
    inputClass += " input";
  }
  if (extraClass) inputClass += " " + extraClass;

  // blocks input for non allowed characters

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label has-text-left-mobile has-text-weight-medium">
          {label}{" "}
          {simpleTooltipText && (
            <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
          )}
        </label>
      </div>
      <div className="field-body">
        <div
          className="field has-addons rev-project-input"
          style={{ width: "100%" }}
        >
          {prefixAddonText && (
            <p className={`control`}>
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{prefixAddonText}</a>
            </p>
          )}
          <p className="control" style={{ width: "100%" }}>
            {/* the value parse method is used to make the number parsing work on chrome. */}
            <input
              inputMode={noDecimals ? "numeric" : "decimal"}
              type="number"
              pattern="[0-9]+[,.]?[0-9]*"
              name={name}
              className={inputClass}
              placeholder={placeholder}
              value={value || ""}
              step={step}
              data-target={dataTarget}
              readOnly={readOnly}
              min={min}
              max={max}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={noDecimals ? onKeyDown : onDecimalKeyDown}
              onPaste={noDecimals ? onPaste : onDecimalPaste}
              onKeyUp={(e) => {
                if (value > max) value = max;
                if (value < min) value = min;
                nextOnEnter(e);
              }}
            />
          </p>
          {addonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{addonText}</a>
            </p>
          )}
        </div>

        {errors && <span className="help is-danger">{errors[0]}</span>}
      </div>
    </div>
  );
};

NumberInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
};

export const DisplayRekenhulp = ({
  label,
  value,
  inputClass = "input",
  extraClass,
  addonText,
  prefixAddonText,
  simpleTooltipText,
}) => {
  if (extraClass) inputClass += " " + extraClass;

  return (
    <>
      <div className="field is-horizontal mb-2">
        <div className="is-normal">
          <label className="label">
            {label}
            {simpleTooltipText && (
              <>
                {" "}
                <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
              </>
            )}
          </label>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field has-addons" style={{ width: "100%" }}>
            {prefixAddonText && (
              <p className={`control`}>
                {/* eslint-disable-next-line  */}
                <a className="button is-static">{prefixAddonText}</a>
              </p>
            )}
            <p className="control" style={{ width: "100%" }}>
              {/* the value parse method is used to make the number parsing work on chrome. */}
              <input
                type="string"
                className={inputClass}
                value={value || ""}
                readOnly={true}
              />
            </p>
            {addonText && (
              <p className="control">
                {/* eslint-disable-next-line  */}
                <a className="button is-static">{addonText}</a>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const NumberInputRekenhulp = ({
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value,
  error: errors,
  dataTarget,
  inputClass,
  extraClass,
  addonText,
  prefixAddonText,
  readOnly,
  min = 0,
  max = 1000000000,
  step,
  noDecimals = false,
  simpleTooltipText,
}) => {
  // set bulma classes
  if (errors && errors.length > 0) {
    inputClass = "is-danger input";
  } else {
    inputClass += " input";
  }
  if (extraClass) inputClass += " " + extraClass;

  // blocks input for non allowed characters

  return (
    <>
      <div className="field is-horizontal mb-2">
        <div className="is-normal">
          <label className="label">
            {label}
            {simpleTooltipText && (
              <>
                {" "}
                <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
              </>
            )}
          </label>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field has-addons" style={{ width: "100%" }}>
            {prefixAddonText && (
              <p className={`control`}>
                {/* eslint-disable-next-line  */}
                <a className="button is-static">{prefixAddonText}</a>
              </p>
            )}
            <p className="control" style={{ width: "100%" }}>
              {/* the value parse method is used to make the number parsing work on chrome. */}
              <input
                inputMode={noDecimals ? "numeric" : "decimal"}
                type="number"
                pattern="[0-9]+[,.]?[0-9]*"
                name={name}
                className={inputClass}
                placeholder={placeholder}
                value={value || ""}
                step={step}
                data-target={dataTarget}
                readOnly={readOnly}
                min={min}
                max={max}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={noDecimals ? onKeyDown : onDecimalKeyDown}
                onPaste={noDecimals ? onPaste : onDecimalPaste}
                onKeyUp={(e) => {
                  if (value > max) value = max;
                  if (value < min) value = min;
                  nextOnEnter(e);
                }}
              />
            </p>
            {addonText && (
              <p className="control">
                {/* eslint-disable-next-line  */}
                <a className="button is-static">{addonText}</a>
              </p>
            )}
          </div>

          {errors && <span className="help is-danger">{errors[0]}</span>}
        </div>
      </div>
    </>
  );
};

NumberInputRekenhulp.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
};

export const NumberWithUnitInput = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  error: errors,
  dataTarget,
  inputClass = "Normal",
  extraClass,
  addonText,
  readOnly,
  min,
  max = 1000000000,
}) => {
  // set bulma classes
  if (errors && errors.length > 0) {
    inputClass = "is-danger input";
  } else {
    inputClass += " input";
  }
  if (extraClass) inputClass += " " + extraClass;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <DangerousDiv
          content={label}
          className="label has-text-left-mobile has-text-weight-medium"
        />
      </div>
      <div className="field-body">
        <div className="field has-addons rev-project-input">
          <p
            className="control rev-project-expandaddon"
            style={{ width: "100%" }}
          >
            <input
              inputMode="decimal"
              type="number"
              pattern="[0-9]+[,.]?[0-9]*"
              name={name}
              className={inputClass}
              placeholder={placeholder}
              value={value || ""}
              data-target={dataTarget}
              readOnly={readOnly}
              min={min}
              max={max}
              onChange={onChange}
              onKeyDown={onDecimalKeyDown}
              onPaste={onDecimalPaste}
              onKeyUp={nextOnEnter}
            />
          </p>
          {addonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{addonText}</a>
            </p>
          )}
        </div>

        {errors && <span className="help is-danger">{errors[0]}</span>}
      </div>
    </div>
  );
};

NumberWithUnitInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
};
