import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "react-router-dom";
const defaultNavbarClasses = "navbar-item";

const RevNavLink = ({ className, text, path, activeClass, icon }) => {
  const classes = className
    ? defaultNavbarClasses.concat(" ", className)
    : defaultNavbarClasses;

  const closeMenu = (e) => {
    const buttons = document.querySelectorAll(".rev-menu-toggle-checkbox");
    buttons.forEach((element) => {
      element.checked = false;
    });

    e.target.blur();
  };

  return (
    <NavLink
      className={`${classes}`}
      activeclassname={activeClass}
      to={path}
      onClick={closeMenu}
    >
      {icon && (
        <>
          <FontAwesomeIcon icon={icon} />
          &nbsp;
        </>
      )}
      {text}
    </NavLink>
  );
};

export default RevNavLink;
