import { useDispatch, useSelector } from "react-redux";
import { CompositeHeader } from "../../../components/header";
import * as projFields from "../projectConstants";
import * as pages from "../../../navigation";
import {
  selectPublicAddressData,
  updatePublicData,
} from "../../../redux/slices/quickStartSlice";
import QuickStartNavigationButtons from "./quickStartNavigationButtons";
import { useEffect, useState } from "react";
import { RadioButtonGroupProject } from "../../../components/common/RadioButtonGroup";
import { SelectInputRow } from "../../../components/common/SelectInput";
import { selectBeheerObjectenByKey } from "../../../redux/slices/vtwSlice";
import { useNavigate, useLocation } from "react-router";

const FinishQuickStart = () => {
  const dispatcher = useDispatch();
  const publicData = useSelector(selectPublicAddressData);
  const location = useLocation();
  const [projectParams, setProjectParams] = useState(publicData);
  const navigate = useNavigate();
  const beheerObj = useSelector((state) =>
    selectBeheerObjectenByKey(state, publicData?.vtwCode)
  );

  useEffect(() => {
    if (!publicData && location === pages.quickStartDone) {
      navigate(pages.home);
    }
  }, [location, navigate, publicData]);

  const updateProjectParams = (e) => {
    let { name, value } = e.target;

    if (name === projFields.includeVat) {
      value = value.toString() === "true";
    } else if (name === projFields.cycle) {
      value = Number(value);
    }
    const tempProjectParams = { ...projectParams, [name]: value };
    setProjectParams(tempProjectParams);
    dispatcher(updatePublicData({ publicData: tempProjectParams }));
  };

  return (
    <>
      {publicData && (
        <>
          <CompositeHeader title="Maak nieuw project aan" />
          <SelectInputRow
            name="basisId"
            label={"Soort kozijnen"}
            onChange={updateProjectParams}
            placeholder={"maak een keuze"}
            value={publicData.basisId}
            options={beheerObj?.basicTypes}
          />

          <RadioButtonGroupProject
            label="Cyclus"
            buttons={[
              {
                text: "Uitponding (25 jaar)",
                value: 25,
                checked: projectParams.cycle === 25,
              },
              {
                text: "Door exploitatie (99 jaar)",
                value: 99,
                checked: projectParams.cycle === 99,
              },
            ]}
            name={projFields.cycle}
            value={publicData.includeVat}
            onChange={updateProjectParams}
            isBool={true}
          />

          <RadioButtonGroupProject
            label="BTW"
            buttons={[
              {
                text: "Exclusief",
                value: false,
                checked: !publicData.includeVat,
              },
              {
                text: "Inclusief",
                value: true,
                checked: publicData.includeVat,
              },
            ]}
            name={projFields.includeVat}
            value={publicData.includeVat}
            onChange={updateProjectParams}
            isBool={true}
          />

          <QuickStartNavigationButtons
            path={`${pages.newproject}/${beheerObj.vtwCode}`}
            nextText="Maak project aan"
            isActive={true}
            showChoose={false}
          />
        </>
      )}
    </>
  );
};

export default FinishQuickStart;
