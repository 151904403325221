import { useDispatch, useSelector } from "react-redux";
import { selectBeheerObjecten } from "../../../redux/slices/vtwSlice";
import {
  selectPublicAddressData,
  updatePublicData,
} from "../../../redux/slices/quickStartSlice";
import { selectIsAuthorized } from "../../../redux/slices/userSlice";
import SortedBeheerObjecten from "./sortedBeheerObjecten";
import { CompositeHeader } from "../../../components/header";
import { useNavigate } from "react-router";
import * as pages from "../../../navigation";
import { QuickStartFloatingBackButton } from "./quickStartNavigationButtons";

const SelectBeheerObject = () => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  let beheerObjecten = useSelector(selectBeheerObjecten);
  const publicData = useSelector(selectPublicAddressData);
  const isAuthorized = useSelector(selectIsAuthorized);
  const selectBuilding = (e, vtw) => {
    if (vtw) {
      const beheerObj = beheerObjecten.find((x) => x.vtwCode === vtw);
      const type = beheerObj.basicTypes.find((x) => x.isActive);
      const temp = {
        cycle: 99,
        includeVat: false,
        ...publicData,
        vtwCode: vtw,
        basisId: type?.id,
      };
      dispatcher(updatePublicData({ publicData: temp }));
      navigate(pages.quickStartDone);
    }
  };

  return (
    <>
      <CompositeHeader
        title="Kies het meest passende object"
        subSubTitle="De meest relevante objecten staan vooraan in de lijst."
      />

      <SortedBeheerObjecten
        beheerObjecten={beheerObjecten}
        isAuthorized={isAuthorized}
        publicData={publicData}
        selectBuilding={selectBuilding}
      />
      <QuickStartFloatingBackButton />
    </>
  );
};

export default SelectBeheerObject;
