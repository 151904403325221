import { RevImgFull } from "../../../components/common/RevImg";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { SpanButton } from "../../../components/common/Buttons";
import Helaas from "../../../components/aboNodig";
import { colorSchemes } from "../projectConstants";
import { PublicSelectionData } from "./publicAddressData";
import { RoofTypesForBeheerObjecten } from "../../../modules/revCalculation";
import { useDispatch, useSelector } from "react-redux";
import { selectObjectSoorten } from "../../../redux/slices/vtwSlice";
import { updatePublicData } from "../../../redux/slices/quickStartSlice";
const SortedBeheerObjecten = ({
  beheerObjecten,
  publicData,
  isAuthorized,
  selectBuilding,
}) => {
  const go25procent = (a, b) => {
    const factor = a.toFixed(4) / b.toFixed(4);
    return factor <= 1.25 && factor >= 0.8;
  };

  const bouwjaarScheeltMinderAls15Jaar = (a, b) => {
    return Math.abs(a - b) <= 15;
  };

  const soortIdMatch = (a, b) => {
    if (a === b) return true;
    if (a === 1) return b === 8;
    if (a === 8) return b === 1;
    return false;
  };

  const objectIdMatch = (a, b) => {
    return a === b;
  };

  const calculateMatchValue = (a) => {
    const matchSoorta = publicData.objectSoort
      ? soortIdMatch(a.objectSoortId, publicData.objectSoort.id)
      : false;
    const matchTypea = publicData.objectType
      ? objectIdMatch(a.objectTypeId, publicData.objectType.id)
      : false;
    const matchRoofa = publicData.roofType
      ? objectIdMatch(a.roofTypeId, publicData.roofType.id)
      : false;
    const matchGoa = go25procent(a.gebruiksOppervlak, publicData.go);
    const matchBouwjaara = bouwjaarScheeltMinderAls15Jaar(
      a.bouwjaar,
      publicData.bouwjaar
    );

    let returnVal = 0;
    returnVal += matchSoorta ? 16 : 0;
    returnVal += matchTypea ? 8 : 0;
    returnVal += matchRoofa ? 2 : 0;
    returnVal += matchGoa ? 1 : 0;
    returnVal += matchBouwjaara ? 4 : 0;

    return returnVal;
  };

  let sorted = beheerObjecten.slice();
  sorted = sorted.sort((a, b) => {
    // get score for building a
    const matchValA = calculateMatchValue(a);
    const matchValB = calculateMatchValue(b);
    return matchValA > matchValB ? -1 : matchValB > matchValA ? 1 : 0;
  });

  // filterselection
  const objectSoorten = useSelector(selectObjectSoorten);
  const dispatcher = useDispatch();

  let objectTypes = [];
  if (publicData?.objectSoort) {
    objectTypes = publicData.objectSoort.objectTypen;
  }

  const roofTypes = RoofTypesForBeheerObjecten(beheerObjecten);

  const updateProjectParams = (e) => {
    let tempProjectParams = { ...publicData };
    const { name } = e.target;
    let value = Number(e.target.value);

    if (name === "objectSoort") {
      tempProjectParams.objectType = undefined;
      tempProjectParams.roofType = undefined;
      value = objectSoorten.find((x) => x.id === value);
    }

    if (name === "objectType") {
      tempProjectParams.roofType = undefined;
      value = objectTypes.find((x) => x.id === value);
    }

    if (name === "roofType") {
      value = roofTypes.find((x) => x.id === value);
    }

    dispatcher(
      updatePublicData({ publicData: { ...tempProjectParams, [name]: value } })
    );
  };

  return (
    <>
      <div className="pb-4 rev-flex-container rev-boxes">
        {/* De witte balk boven het sticky box gedeelte */}
        <div
          className="has-background-white is-hidden-desktop is-hidden-mobile"
          style={{
            position: "sticky",
            top: "2em",
            width: "100%",
            height: "100px",
            zIndex: 10,
          }}
        ></div>
        <div
          className="section box has-background-white is-hidden-mobile"
          style={{
            borderRadius: "0px",
            position: "sticky",
            top: "5.5em",
            width: "100%",
            zIndex: 11,
          }}
        >
          <PublicSelectionData
            publicData={publicData}
            roofTypes={roofTypes}
            updateProjectParams={updateProjectParams}
            objectSoorten={objectSoorten}
            objectTypes={objectTypes}
          />
        </div>
        <div className="is-hidden-tablet">
          <PublicSelectionData
            publicData={publicData}
            roofTypes={roofTypes}
            updateProjectParams={updateProjectParams}
            objectSoorten={objectSoorten}
            objectTypes={objectTypes}
          />
        </div>
        {sorted.map((beheerObject, i) => {
          const highlightClass = colorSchemes[beheerObject.objectSoortId];
          return (
            <div
              key={beheerObject.id}
              className="rev-flex-item box is-shadowless "
              data-target={`${beheerObject.vtwCode}-modal`}
              onClick={(e) => selectBuilding(e, beheerObject.vtwCode)}
              style={{ cursor: "pointer" }}
            >
              <h1
                className={`has-text-centered has-text-weight-bold rev-title-newproject ${highlightClass}`}
              >
                {beheerObject.searchVtwCode}
              </h1>
              <LazyLoadComponent visibleByDefault={false}>
                {beheerObject.image && (
                  <div className="has-text-centered">
                    <RevImgFull
                      src={beheerObject.image.src}
                      alt={beheerObject.image.altText}
                      className="modal-button rev-img-fit"
                      data-target="modal-image2"
                    />
                  </div>
                )}
              </LazyLoadComponent>
              <div className="content rev-hide-overflow">
                <h4 className="p-2 mb-2 is-size-5 has-text-weight-medium has-text-centered">
                  {beheerObject.description}
                </h4>
                <table className="table table-no-border is-size-6 has-background-white-ter is-mobile">
                  <tbody>
                    <tr>
                      <td
                        className="has-text-right has-text-weight-bold"
                        style={{ border: "none" }}
                      >
                        Object soort:
                      </td>
                      <td style={{ border: "none" }}>
                        <span
                          className={`${
                            publicData.objectSoort &&
                            soortIdMatch(
                              beheerObject.objectSoortId,
                              publicData.objectSoort.id
                            )
                              ? "has-text-success"
                              : "has-text-warning"
                          }`}
                        >
                          {beheerObject.objectSoort}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="has-text-right has-text-weight-bold"
                        style={{ border: "none" }}
                      >
                        Object type:
                      </td>
                      <td style={{ border: "none" }}>
                        <span
                          className={`${
                            publicData.objectType &&
                            objectIdMatch(
                              beheerObject.objectTypeId,
                              publicData.objectType.id
                            )
                              ? "has-text-success"
                              : "has-text-warning"
                          }`}
                        >
                          {beheerObject.objectType}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="has-text-right has-text-weight-bold"
                        style={{ border: "none" }}
                      >
                        Bouwjaar:
                      </td>
                      <td style={{ border: "none" }}>
                        <span
                          className={`${
                            bouwjaarScheeltMinderAls15Jaar(
                              beheerObject.bouwjaar,
                              publicData.bouwjaar
                            )
                              ? "has-text-success"
                              : "has-text-warning"
                          }`}
                        >
                          {beheerObject.bouwjaar}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="has-text-right has-text-weight-bold"
                        style={{ border: "none" }}
                      >
                        Dak type:
                      </td>
                      <td style={{ border: "none" }}>
                        <span
                          className={`${
                            publicData.roofType &&
                            objectIdMatch(
                              beheerObject.roofTypeId,
                              publicData.roofType.id
                            )
                              ? "has-text-success"
                              : "has-text-warning"
                          }`}
                        >
                          {beheerObject.roofType}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="has-text-right has-text-weight-bold">
                        GO:
                      </td>
                      <td style={{ border: "none" }}>
                        <span
                          className={`${
                            go25procent(
                              beheerObject.gebruiksOppervlak,
                              publicData.go
                            )
                              ? "has-text-success"
                              : "has-text-warning"
                          }`}
                        >
                          {beheerObject.gebruiksOppervlak}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="has-text-centered mb-2 is-vbottom">
                  <SpanButton text="Kies object" className="is-rounded" />
                </div>
              </div>
            </div>
          );
        })}
        {!isAuthorized && <Helaas />}
      </div>
    </>
  );
};

export default SortedBeheerObjecten;
