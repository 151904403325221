import ProjectBouwdeel from "./ProjectBouwdeel";
import {
  GetOnderhoudskostenDisplay,
  SetOnderhoudskostenDisplay,
} from "./ProjectSession";
import {
  CalculateBeheerObject,
  RevRoundNumber,
} from "../../modules/revCalculation";
import { SelectInputRow } from "../../components/common/SelectInput";
import { ToggleDisplayButton } from "../../components/toggleDisplayButton";
import * as classes from "../../custom-scripts/custom-javascript-classes";
import {
  DisplayLabel,
  ProjectHeaderDisplayLabel,
} from "../../components/common/DisplayLabel";

const ProjectOnderhoudskosten = ({
  project,
  onChangeBouwdeel,
  onSelectBouwdeel,
  onRemovebouwdeel,
  onChangeHandeling,
  bouwdeelId,
  bouwdelen,
  handelingen,
  startYear,
  onderhoudAccess,
  errors = {},
}) => {
  // calculations are executed here. could be better and executed perbouwdeel
  let displayState = GetOnderhoudskostenDisplay();
  const handleDisplayStateChange = (e) => {
    const box = e.target.closest(`.${classes.toggleBox}`);
    const target = box.dataset.target;
    displayState[target] = !displayState[target];
    SetOnderhoudskostenDisplay(displayState);
  };

  const getInactiveSortedBouwdelen = ({ bouwdelen, soortId }) => {
    return Object.values(bouwdelen)
      .filter((bouwdeel) => !bouwdeel.active && bouwdeel.soortId === soortId)
      .sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
      );
  };

  const calculationResults = CalculateBeheerObject({
    beheerObject: project,
    bouwdelen: bouwdelen,
    handelingen: handelingen,
  });

  const bouwjaar = Number(project.bouwjaar);
  const endYearSelection = startYear + 25 - bouwjaar;

  const yearSelection = [{ id: "", name: "bouwjaar" }];
  for (let i = 0; i < endYearSelection; i++) {
    yearSelection.push({
      id: bouwjaar + i,
      name: bouwjaar + i,
    });
  }

  const inactiveBasicBouwdelen = getInactiveSortedBouwdelen({
    bouwdelen,
    soortId: 0,
  });

  const inactiveInstallatieBouwdelen = getInactiveSortedBouwdelen({
    bouwdelen,
    soortId: 1,
  });

  const inactiveDuurzaamheidBouwdelen = getInactiveSortedBouwdelen({
    bouwdelen,
    soortId: 2,
  });

  const inactiveGebrekBouwdelen = getInactiveSortedBouwdelen({
    bouwdelen,
    soortId: 3,
  });

  const inactiveVerbeteringBouwdelen = getInactiveSortedBouwdelen({
    bouwdelen,
    soortId: 4,
  });

  return (
    <section className="is-small pb-1">
      <div className="container rev-project-box-child-margin">
        <DisplayLabel
          label={"Referentie VTW-code"}
          text={project.reference.searchVtwCode}
          className="rev-is-borderless"
          isCompact={true}
        />
        <DisplayLabel
          label={"Object type"}
          text={project.reference.objectType}
          className="rev-is-borderless"
          isCompact={true}
        />
        <DisplayLabel
          label={"Aanmaakdatum"}
          text={project.createDate}
          className="rev-is-borderless"
          isCompact={true}
        />
      </div>

      <div className="container rev-project-box-child-margin">
        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"basisBouwdelenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["basisBouwdelenToggleBox"]}
            onClick={handleDisplayStateChange}
          >
            <ProjectHeaderDisplayLabel
              label={`Jaarlijkse kosten:`}
              text={`€ ${RevRoundNumber(calculationResults.basisTotal)}`}
            />
          </ToggleDisplayButton>

          <div id="basisBouwdelenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding rev-project-box-child-margin">
              <SelectInputRow
                name="bouwdeelId"
                value={bouwdeelId || ""}
                label="Kies nieuw bouwdeel"
                onChange={onSelectBouwdeel}
                placeholder="Kies een bouwdeel om toe te voegen"
                options={inactiveBasicBouwdelen}
                optionsHeader="Basis"
                options2={inactiveInstallatieBouwdelen}
                options2Header="Installatie"
              />
              {calculationResults.basisBouwdelen.map((bouwdeel) => {
                return (
                  <div key={`bouwdeel-${bouwdeel.source.id}`}>
                    <ProjectBouwdeel
                      beheerObjectAmount={project.bvo}
                      bouwdeel={bouwdeel}
                      yearSelection={yearSelection}
                      onChangeBouwdeel={onChangeBouwdeel}
                      onRemovebouwdeel={onRemovebouwdeel}
                      onChangeHandeling={onChangeHandeling}
                      handleDisplayStateChange={handleDisplayStateChange}
                      displayState={displayState}
                      onderhoudAccess={onderhoudAccess}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"duurzaamheidBouwdelenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["duurzaamheidBouwdelenToggleBox"]}
            onClick={handleDisplayStateChange}
          >
            {
              <ProjectHeaderDisplayLabel
                label={`Eenmalige duurzaamheidskosten:`}
                text={`€ ${RevRoundNumber(
                  calculationResults.duurzaamheidTotal
                )}`}
              />
            }
          </ToggleDisplayButton>

          <div id="duurzaamheidBouwdelenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding rev-project-box-child-margin">
              <SelectInputRow
                name="bouwdeelId"
                value={bouwdeelId || ""}
                label="Kies nieuw bouwdeel"
                onChange={onSelectBouwdeel}
                placeholder="Kies een bouwdeel om toe te voegen"
                options={inactiveDuurzaamheidBouwdelen}
              />
              {calculationResults.duurzaamheidBouwdelen.map((bouwdeel) => {
                return (
                  <div key={`bouwdeel-${bouwdeel.source.id}`}>
                    <ProjectBouwdeel
                      beheerObjectAmount={project.bvo}
                      bouwdeel={bouwdeel}
                      yearSelection={yearSelection}
                      onChangeBouwdeel={onChangeBouwdeel}
                      onRemovebouwdeel={onRemovebouwdeel}
                      onChangeHandeling={onChangeHandeling}
                      handleDisplayStateChange={handleDisplayStateChange}
                      displayState={displayState}
                      onderhoudAccess={onderhoudAccess}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"gebrekBouwdelenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["gebrekBouwdelenToggleBox"]}
            onClick={handleDisplayStateChange}
          >
            <ProjectHeaderDisplayLabel
              label={`Eenmalige gebrekskosten:`}
              text={`€ ${RevRoundNumber(calculationResults.gebrekTotal)}`}
            />
          </ToggleDisplayButton>

          <div id="gebrekBouwdelenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding rev-project-box-child-margin">
              <SelectInputRow
                name="bouwdeelId"
                value={bouwdeelId || ""}
                label="Kies nieuw bouwdeel"
                onChange={onSelectBouwdeel}
                placeholder="Kies een bouwdeel om toe te voegen"
                options={inactiveGebrekBouwdelen}
              />
              {calculationResults.gebrekBouwdelen.map((bouwdeel) => {
                return (
                  <div key={`bouwdeel-${bouwdeel.source.id}`}>
                    <ProjectBouwdeel
                      beheerObjectAmount={project.bvo}
                      bouwdeel={bouwdeel}
                      yearSelection={yearSelection}
                      onChangeBouwdeel={onChangeBouwdeel}
                      onRemovebouwdeel={onRemovebouwdeel}
                      onChangeHandeling={onChangeHandeling}
                      handleDisplayStateChange={handleDisplayStateChange}
                      displayState={displayState}
                      onderhoudAccess={onderhoudAccess}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="rev-folding-box">
          <ToggleDisplayButton
            dataTarget={"verbeteringBouwdelenToggleBox"}
            className={"rev-box-title"}
            isClosed={!displayState["verbeteringBouwdelenToggleBox"]}
            onClick={handleDisplayStateChange}
          >
            <ProjectHeaderDisplayLabel
              label={`Eenmalige verbeteringskosten:`}
              text={`€ ${RevRoundNumber(calculationResults.verbeteringTotal)}`}
            />
          </ToggleDisplayButton>

          <div id="verbeteringBouwdelenToggleBox" className="rev-menu-open">
            <div className="rev-project-box-internal-padding rev-project-box-child-margin">
              <SelectInputRow
                name="bouwdeelId"
                value={bouwdeelId || ""}
                label="Kies nieuw bouwdeel"
                onChange={onSelectBouwdeel}
                placeholder="Kies een bouwdeel om toe te voegen"
                options={inactiveVerbeteringBouwdelen}
              />
              {calculationResults.verbeteringBouwdelen.map((bouwdeel) => {
                return (
                  <div key={`bouwdeel-${bouwdeel.source.id}`}>
                    <ProjectBouwdeel
                      beheerObjectAmount={project.bvo}
                      bouwdeel={bouwdeel}
                      yearSelection={yearSelection}
                      onChangeBouwdeel={onChangeBouwdeel}
                      onRemovebouwdeel={onRemovebouwdeel}
                      onChangeHandeling={onChangeHandeling}
                      handleDisplayStateChange={handleDisplayStateChange}
                      displayState={displayState}
                      onderhoudAccess={onderhoudAccess}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectOnderhoudskosten;
