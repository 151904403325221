import PropTypes from "prop-types";
import { ControlledInput, ControlledTextArea } from "./controlledInput";
import { RevTooltipSimple } from "./revTooltip";
// https://bulma.io/documentation/form/input/
// extra class options:
// medium/large

const nextOnEnter = (e) => {
  if (e.key === "Enter") {
    const inputs = Array.from(document.querySelectorAll("input,select"));
    let index = inputs.indexOf(e.target) + 1;
    if (index === inputs.length) {
      index = 0;
    }
    inputs[index].focus();
  }
};

export const TextInputSimple = ({
  name,
  label,
  onChange,
  placeholder,
  dataTarget,
  value,
  error: errors,
  className,
}) => {
  if (errors && errors.lenth > 0) {
    className = className + " is-danger";
  }
  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <div
        className={`is-link `}
        style={{ maxWidth: "200px", marginLeft: "auto", marginRight: "auto" }}
      >
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <ControlledInput
          name={name}
          value={value}
          onChange={onChange}
          onKeyUp={nextOnEnter}
          className={`form-control input is-centered ${className}`}
          data-target={dataTarget}
          placeholder={placeholder}
        />
        {errors && <span className="help is-danger">{errors[0]}</span>}
      </div>
    </>
  );
};

TextInputSimple.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.object),
};

const TextInputField = ({
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value,
  error: errors,
  target,
  inputClass = "normal",
  extraClass,
  prefixAddonText,
  addonText,
  readOnly,
  maxLength = "400",
  simpleTooltipText,
}) => {
  // set bulma classes
  if (errors && errors.length > 0) {
    inputClass = "is-danger input";
  } else {
    inputClass += " input";
  }
  if (extraClass) {
    inputClass += " " + extraClass;
  }

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label has-text-left-mobile has-text-weight-medium">
          {label}{" "}
          {simpleTooltipText && (
            <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
          )}
        </label>
      </div>
      <div className="field-body">
        <div className="field  has-addons rev-project-input">
          {prefixAddonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{prefixAddonText}</a>
            </p>
          )}
          <p className="control" style={{ width: "100%" }}>
            <ControlledInput
              type="text"
              name={name}
              className={inputClass}
              placeholder={placeholder}
              value={value || ""}
              onChange={onChange}
              onBlur={onBlur}
              data-target={target}
              readOnly={readOnly}
              maxLength={maxLength}
              onKeyUp={nextOnEnter}
            />
            {errors && <span className="help is-danger">{errors[0]}</span>}
          </p>
          {addonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{addonText}</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

TextInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array,
};

export default TextInputField;

export const TextAreaInput = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  error: errors,
  target,
  inputClass = "textarea",
  extraClass,
}) => {
  // set bulma classes
  if (errors && errors.length > 0) {
    inputClass = "is-danger input";
  } else {
    inputClass += " input";
  }
  if (extraClass) inputClass += " " + extraClass;

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{label}</label>
      </div>
      <div className="field-body">
        <div className="field rev-project-input">
          <p className="control">
            <ControlledTextArea
              name={name}
              className={inputClass}
              placeholder={placeholder}
              value={value || ""}
              onChange={onChange}
              data-target={target}
            />
            {errors && <span className="help is-danger">{errors[0]}</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

TextInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array,
};
