import { RevRoundNumber } from "../../modules/revCalculation";
import { DangerousDiv } from "../../components/dangerousGerneric";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectUserDataLoadState } from "../../redux/slices/userSlice";
import { useEffect } from "react";
import * as states from "../../redux/states";
import { RedirectToLoginWithReturnUrl } from "../project/projectFunctions";
function ArticleMaintenance({ article, isAuthorized, state }) {
  const navigate = useNavigate();
  const isLoaded = useSelector(selectUserDataLoadState);
  useEffect(() => {
    if (
      article &&
      article?.contentRequiresAccount &&
      (state === states.unauthorized ||
        (!isAuthorized && isLoaded === states.succeeded))
    ) {
      RedirectToLoginWithReturnUrl(navigate);
    }
  }, [state, navigate, isAuthorized, isLoaded, article]);

  const name = article.contents.filter((x) => x.name === "name")[0];
  const remarks = article.contents.filter((x) => x.name === "remarks")[0];
  const price = article.contents.filter((x) => x.name === "price")[0];
  const unit = article.contents.filter((x) => x.name === "unit")[0];
  const cycle = article.contents.filter((x) => x.name === "cycle")[0];

  const BedragArbeid = article.contents.filter(
    (x) => x.name === "BedragArbeid"
  )[0];
  const BedragMateriaal = article.contents.filter(
    (x) => x.name === "BedragMateriaal"
  )[0];
  const BedragMaterieel = article.contents.filter(
    (x) => x.name === "BedragMaterieel"
  )[0];
  const BedragOnderaannemer = article.contents.filter(
    (x) => x.name === "BedragOnderaannemer"
  )[0];
  const BedragStelpost = article.contents.filter(
    (x) => x.name === "BedragStelpost"
  )[0];

  const WinstEnRisico = article.contents.filter(
    (x) => x.name === "WinstEnRisico"
  )[0];
  const AlgemeneKosten = article.contents.filter(
    (x) => x.name === "AlgemeneKosten"
  )[0];

  return (
    <>
      <div className="hero-body" style={{ alignItems: "start" }}>
        <div className="container">
          <h2 className="subtitle is-4">
            {article.title} {name.content}
            <p className="is-italic is-size-6 has-text-weight-normal">
              Prijzen zijn excl. BTW
            </p>
          </h2>

          <div className="columns is-vtop is-mobile">
            <div className="column is-2 is-hidden-touch">
              <div>
                <strong>Eenheid:</strong>
              </div>
              <div>
                <strong>Cyclus:</strong>
              </div>
              <div>
                <strong>Kosten:</strong>
              </div>
              <div>
                <strong>Is duurzaam:</strong>
              </div>
              {remarks.content && (
                <div>
                  <strong>Opmerkingen:</strong>
                </div>
              )}
            </div>
            <div className="column is-6 is-hidden-desktop">
              <div>
                <strong>Eenheid:</strong>
              </div>
              <div>
                <strong>Cyclus:</strong>
              </div>
              <div>
                <strong>Kosten:</strong>
              </div>
              <div>
                <strong>Is duurzaam:</strong>
              </div>
              {remarks.content && (
                <div>
                  <strong>Opmerkingen:</strong>
                </div>
              )}
            </div>
            <div className="column is-6">
              <DangerousDiv content={unit.content} />
              <div>{cycle.content}</div>
              <div>{`€ ${RevRoundNumber(price.content)}`}</div>
              {remarks.content && <div>{remarks.content}</div>}
            </div>
          </div>
          <h3 className="subtitle is-5 is-vcentered">Kosten gesplitst</h3>
          <div className="columns is-vtop is-mobile">
            <div className="column is-2 is-hidden-touch">
              {BedragArbeid.content && (
                <div>
                  <strong>Arbeid:</strong>
                </div>
              )}
              {BedragMateriaal.content && (
                <div>
                  <strong>Materiaal:</strong>
                </div>
              )}
              {BedragMaterieel.content && (
                <div>
                  <strong>Materieel:</strong>
                </div>
              )}
              {BedragOnderaannemer.content && (
                <div>
                  <strong>Onderaannemer:</strong>
                </div>
              )}
              {BedragStelpost.content && (
                <div>
                  <strong>Stelpost:</strong>
                </div>
              )}
              {WinstEnRisico.content && (
                <div>
                  <strong>Winst en risico:</strong>
                </div>
              )}
              {AlgemeneKosten.content && (
                <div>
                  <strong>Algemeen:</strong>
                </div>
              )}
            </div>
            <div className="column is-6 is-hidden-desktop">
              {BedragArbeid.content && (
                <div>
                  <strong>Arbeid:</strong>
                </div>
              )}
              {BedragMateriaal.content && (
                <div>
                  <strong>Materiaal:</strong>
                </div>
              )}
              {BedragMaterieel.content && (
                <div>
                  <strong>Materieel:</strong>
                </div>
              )}
              {BedragOnderaannemer.content && (
                <div>
                  <strong>Onderaannemer:</strong>
                </div>
              )}
              {BedragStelpost.content && (
                <div>
                  <strong>Stelpost:</strong>
                </div>
              )}
              {WinstEnRisico.content && (
                <div>
                  <strong>Winst en risico:</strong>
                </div>
              )}
              {AlgemeneKosten.content && (
                <div>
                  <strong>Algemeen:</strong>
                </div>
              )}
            </div>
            <div className="column is-6">
              {BedragArbeid.content && (
                <div>{`€ ${RevRoundNumber(BedragArbeid.content)}`}</div>
              )}
              {BedragMateriaal.content && (
                <div>{`€ ${RevRoundNumber(BedragMateriaal.content)}`}</div>
              )}
              {BedragMaterieel.content && (
                <div>{`€ ${RevRoundNumber(BedragMaterieel.content)}`}</div>
              )}
              {BedragOnderaannemer.content && (
                <div>{`€ ${RevRoundNumber(BedragOnderaannemer.content)}`}</div>
              )}
              {BedragStelpost.content && (
                <div>{`€ ${RevRoundNumber(BedragStelpost.content)}`}</div>
              )}
              {WinstEnRisico.content && (
                <div>{`€ ${RevRoundNumber(WinstEnRisico.content)}`}</div>
              )}
              {AlgemeneKosten.content && (
                <div>{`€ ${RevRoundNumber(AlgemeneKosten.content)}`}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticleMaintenance;

// https://github.com/BulmaTemplates/bulma-templates/blob/master/templates/cards.html
