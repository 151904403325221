import { NumberInput } from "../../../components/common/NumberInput";
import { RevImgFull } from "../../../components/common/RevImg";
import { SelectInputRow } from "../../../components/common/SelectInput";
import * as projFields from "../projectConstants";

export const PublicSelectionData = ({
  publicData,
  objectSoorten,
  objectTypes,
  roofTypes,
  updateProjectParams,
}) => {
  return (
    <div className="columns">
      <div className="column">
        <RevImgFull src={publicData.buildingImage?.url} width="250" />
      </div>
      <div className="column">
        <SelectInputRow
          name="objectSoort"
          label={"Soort"}
          onChange={updateProjectParams}
          placeholder={"maak een keuze"}
          value={publicData.objectSoort?.id}
          options={objectSoorten}
        />

        {publicData.objectSoort && (
          <SelectInputRow
            name={"objectType"}
            onChange={updateProjectParams}
            label="Type"
            value={publicData.objectType?.id}
            placeholder="maak een keuze"
            options={objectTypes}
          />
        )}
        <NumberInput
          label="Bouwjaar"
          name={projFields.bouwjaar}
          value={publicData.bouwjaar}
          onChange={updateProjectParams}
        />

        {publicData.objectType && (
          <SelectInputRow
            name={"roofType"}
            onChange={updateProjectParams}
            label="Daktype"
            value={publicData.roofType?.id}
            options={roofTypes}
          />
        )}
        <NumberInput
          label="GO"
          simpleTooltipText={
            "Gebruiksoppervlakte kunt u hier nog wijzigen indien gewenst."
          }
          name={projFields.go}
          value={publicData.go}
          onChange={updateProjectParams}
        />
      </div>
    </div>
  );
};
