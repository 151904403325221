import { HubConnectionBuilder } from "@microsoft/signalr";

import * as projectProps from "../projectConstants";
import * as pages from "../../../navigation";
import * as states from "../../../redux/states";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { SelectInputRow } from "../../../components/common/SelectInput";
import { AnchorButtonPrimary } from "../../../components/common/Buttons";
import {
  selectSystemYear,
  selectCalculationYears,
  selectUserComplexes,
  getYearSelection,
  selectUserComplexesMjopFiles,
  getComplexMjops,
  addMjopRequest,
  selectUserComplexesMjopFilesState,
} from "../../../redux/slices/userProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  requestMJOPForComplex,
  selectBearerToken,
  selectProfile,
  userHasProSubscription,
} from "../../../redux/slices/userSlice";
import { NumberInput } from "../../../components/common/NumberInput";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteUserFile,
  downloadUserFile,
} from "../../../redux/slices/fileDownloadSlice";
import Loader from "../../../components/loader";

const ComplexPlanManager = () => {
  var dispatcher = useDispatch();
  const navigate = useNavigate();
  const startYear = Number(useSelector(selectSystemYear));
  const [mjopData, setMjopData] = useState();

  const [signalR, setSignalR] = useState();
  const yearSelection = useSelector(selectCalculationYears);
  const files = useSelector(selectUserComplexesMjopFiles);
  const filesState = useSelector(selectUserComplexesMjopFilesState);
  const userProfile = useSelector(selectProfile);
  const accessToken = useSelector(selectBearerToken);
  const userComplexes = [...useSelector(selectUserComplexes)]
    .sort()
    .map((c) => {
      return { id: c.name, name: c.name };
    });

  useEffect(() => {
    if (!signalR) {
      const signalRConn = new HubConnectionBuilder()
        .withUrl("/signalr/mjop", { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .configureLogging("Information")
        .build();

      signalRConn
        .start()
        .then(() => {
          try {
            // connected to signalR, now add to group
            signalRConn.invoke("joinGroup", userProfile.sub);
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => console.error("Error connecting to hub:", err));

      signalRConn.on("mjop_done", () => {
        dispatcher(getComplexMjops());
      });

      setSignalR(signalRConn);
    }
  }, [dispatcher, signalR, userProfile, accessToken]);

  useEffect(() => {
    if (yearSelection === undefined) {
      dispatcher(getYearSelection());
    } else if (filesState === states.idle) {
      dispatcher(getComplexMjops());
    } else if (mjopData === undefined) {
      setMjopData({
        startYear: startYear,
        period: 60,
        year: 2023,
        cycle: 99,
      });
    } else if (userComplexes.length === 0) {
      navigate(pages.userProjectsPage);
    }
  }, [
    dispatcher,
    yearSelection,
    navigate,
    userComplexes,
    mjopData,
    startYear,
    filesState,
  ]);

  // retrieve session from selector

  const updateMjopSettings = (name, value) => {
    setMjopData((mjopData) => ({
      ...mjopData,
      [name]: value,
    }));
  };

  const downloadOnClick = (e) => {
    var elementWithTarget = e.target.closest(`.button`);
    const fileName = elementWithTarget.dataset.target;
    dispatcher(downloadUserFile({ fileName })).catch((x) => {
      console.error(x);
      dispatcher(getComplexMjops());
    });
  };

  const deleteOnClick = (e) => {
    var elementWithTarget = e.target.closest(`.button`);
    const fileName = elementWithTarget.dataset.target;

    dispatcher(deleteUserFile({ fileName })).finally((x) => {
      dispatcher(getComplexMjops());
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    updateMjopSettings(name, value);
  };

  const createMjopForComplex = (e) => {
    const indexOf = files.findIndex(
      (x) => x.fileName.includes(mjopData.complex) && !x.canDownload
    );

    if (indexOf === -1) {
      dispatcher(addMjopRequest({ complex: mjopData.complex }));
      dispatcher(
        requestMJOPForComplex({
          clientConnectionId: userProfile.sub,
          ...mjopData,
        })
      );
    }
  };

  const endYearSelection = startYear + 25 - 1900;
  const yearBouwSelection = [];
  for (let i = 0; i < endYearSelection; i++) {
    yearBouwSelection.push({
      id: Number(1900) + i,
      name: Number(1900) + i,
    });
  }

  const periodSelection = [];
  for (let i = 1; i <= 20; i++) {
    periodSelection.push({ id: i * 5, name: i * 5 });
  }
  const sortedFiles = [...files].sort((a, b) => {
    if (a.canDownload === b.canDownload) {
      return a.fileName < b.fileName;
    }
    return a.canDownload > b.canDownload;
  });

  const userIsProSubscription = useSelector(userHasProSubscription);
  return (
    <>
      <section className="section">
        <div style={{ paddingTop: "10px" }}>
          <div className="container pb-5 is-mobile-pb-1">
            <div className="is-hidden-tablet">
              <h1
                className="title is-size-5-mobile has-text-weight-bold"
                style={{ display: "inline-block" }}
              >
                Projecten
              </h1>
              <br />
              <Link
                className={"button is-primary my-1"}
                to={pages.quickStartAddress}
              >
                Quickstart
              </Link>

              <br />
              <Link className={"button is-primary my-1"} to={pages.newproject}>
                Overzicht Referentiegebouwen
              </Link>

              <br />
              <Link
                className={"button is-primary my-1"}
                to={pages.userProjectsPage}
              >
                Projecten pagina
              </Link>
            </div>
            <div className="is-hidden-mobile">
              <h1
                className="title is-size-5-mobile has-text-weight-bold"
                style={{ display: "inline-block" }}
              >
                Projecten
              </h1>
              &nbsp; &nbsp; &nbsp;&nbsp;
              <Link
                className={"button is-primary"}
                to={pages.quickStartAddress}
              >
                Quickstart
              </Link>
              &nbsp;&nbsp;
              <Link className={"button is-primary"} to={pages.newproject}>
                Overzicht Referentiegebouwen
              </Link>
              &nbsp;&nbsp;
              <Link className={"button is-primary"} to={pages.userProjectsPage}>
                Projecten pagina
              </Link>
            </div>
          </div>
        </div>
        {mjopData && userComplexes && yearSelection && (
          <div className="container has-text-centered rev-project-box-child-margin">
            <div className="rev-folding-box">
              <div
                data-target={"parametersToggleBox"}
                className={"rev-box-title"}
                type={"parameters"}
              >
                {"Voorkeuren voor MJOP"}
              </div>

              <div id="parametersToggleBox" className="rev-menu-open">
                <div className="rev-project-box-internal-padding">
                  <SelectInputRow
                    name="complex"
                    label={"Complex"}
                    placeholder={"Kies complex"}
                    value={mjopData.complex}
                    onChange={onChange}
                    options={userComplexes}
                  />

                  <SelectInputRow
                    label="Start jaar"
                    name="startYear"
                    options={yearBouwSelection}
                    value={mjopData.startYear}
                    onChange={onChange}
                  />

                  <SelectInputRow
                    label="Beschouwings periode"
                    name="period"
                    options={periodSelection}
                    value={mjopData.period}
                    onChange={onChange}
                    addonText="jaar"
                    simpleTooltipText="Periode waarover het onderhoudsplan wordt berekend."
                  />
                  <NumberInput
                    label={"Cyclus"}
                    value={mjopData.cycle}
                    name={projectProps.cycle}
                    onChange={onChange}
                    simpleTooltipText="Standaard is het uitpondmodel ingesteld met een cyclus tot en met 25 jaar. In dit geval worden de onderhoudselementen met een cyclus hoger dan 25 jaar niet meegenomen in de calculaties. Voor doorexploitatie vult u een waarde in tussen 26 en 99 en worden de daarbij behorende onderhoudselementen wel meegenomen."
                    noDecimals={true}
                  />

                  <SelectInputRow
                    label="Prijspeil"
                    name={projectProps.year}
                    options={yearSelection}
                    onChange={onChange}
                    value={mjopData.year}
                    addonText={"jaar"}
                    simpleTooltipText="Prijzen worden berekend met het geselecteerde peiljaar."
                  />

                  <hr />
                  {userIsProSubscription && (
                    <AnchorButtonPrimary
                      text="Genereer MJOP voor complex"
                      onClick={createMjopForComplex}
                      disabled={!mjopData.complex}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {files.length > 0 && (
          <>
            <div className="container is-hidden-tablet">
              <h1 className="title is-size-5-mobile has-text-weight-bold">
                Bestaande documenten
              </h1>
              {sortedFiles.map((x) => (
                <div
                  key={x.fileName}
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "table",
                    }}
                  >
                    <div
                      className="has-text-centered button box"
                      style={{ display: "inline-flex" }}
                      onClick={downloadOnClick}
                      data-target={x.fileName}
                    >
                      {x.fileName}
                    </div>
                    {!x.canDownload && (
                      <div className="box" style={{ display: "inline-flex" }}>
                        <Loader />
                      </div>
                    )}
                    {x.canDownload && (
                      <>
                        <div
                          className="has-text-centered button box"
                          style={{ display: "inline-flex" }}
                          onClick={downloadOnClick}
                          data-target={x.fileName}
                        >
                          <FontAwesomeIcon
                            className="has-text-success"
                            icon={faDownload}
                          />{" "}
                        </div>
                        <div
                          className="has-text-centered button box"
                          style={{ display: "inline-flex" }}
                          onClick={deleteOnClick}
                          data-target={x.fileName}
                        >
                          <FontAwesomeIcon
                            className="has-text-danger"
                            icon={faDeleteLeft}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="list container is-hidden-mobile"
              style={{ float: "clear" }}
            >
              <h1 className="title is-size-5-mobile has-text-weight-bold">
                {" "}
                Bestaande documenten
              </h1>
              {sortedFiles.map((x) => (
                <div className="list-item columns" key={x.fileName}>
                  <div
                    className="column is-four-fifths box has-text-centered button"
                    onClick={downloadOnClick}
                    data-target={x.fileName}
                  >
                    {x.fileName}
                  </div>
                  {!x.canDownload && (
                    <div className="column">
                      <Loader />
                    </div>
                  )}
                  {x.canDownload && (
                    <>
                      <div
                        className="column box has-text-centered button"
                        onClick={downloadOnClick}
                        data-target={x.fileName}
                      >
                        <FontAwesomeIcon
                          className="has-text-success"
                          icon={faDownload}
                        />{" "}
                      </div>
                      <div
                        className="column box has-text-centered button"
                        onClick={deleteOnClick}
                        data-target={x.fileName}
                      >
                        <FontAwesomeIcon
                          className="has-text-danger"
                          icon={faDeleteLeft}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default ComplexPlanManager;
