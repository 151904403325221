import Select from "react-select";
import PropTypes from "prop-types";
import { RevTooltipSimple } from "./revTooltip";

const nextOnEnter = (e) => {
  if (e.key === "Enter") {
    const inputs = Array.from(document.querySelectorAll("input,select"));
    let index = inputs.indexOf(e.target) + 1;
    if (index === inputs.length) {
      index = 0;
    }
    inputs[index].focus();
  }
};

export const SelectInput = ({
  name,
  label,
  onChange,
  placeholder,
  dataTarget,
  value,
  error: errors,
  options,
  className,
}) => {
  if (errors && errors.lenth > 0) {
    className = className + " is-danger";
  }
  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <div className="select is-link">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control input ${className}`}
          data-target={dataTarget}
          onKeyUp={nextOnEnter}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </select>
        {errors && <span className="help is-danger">{errors[0]}</span>}
      </div>
    </>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.object),
};

export const SelectInputRowRekenhulp = ({
  name,
  label,
  onChange,
  placeholder,
  dataTarget,
  value,
  error: errors,
  options,
  optionsHeader,
  options2,
  options2Header,
  className,
  addonText,
  simpleTooltipText,
}) => {
  if (errors && errors.lenth > 0) {
    className = className + " is-danger";
  }

  return (
    <>
      <div className="field is-horizontal">
        <div className="is-normal">
          <label className="label">
            {label}{" "}
            {simpleTooltipText && (
              <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
            )}
          </label>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field has-addons">
            <p className="control select rev-project-expandaddon">
              <select
                name={name}
                value={value || ""}
                onChange={onChange}
                onKeyUp={nextOnEnter}
                className={`form-control input ${className}`}
                data-target={dataTarget}
              >
                {placeholder && <option value="">{placeholder}</option>}
                {optionsHeader && (
                  <optgroup label={optionsHeader}>
                    {options.map((option, i) => {
                      return (
                        <option key={`${name}${i}`} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </optgroup>
                )}
                {!optionsHeader && (
                  <>
                    {options.map((option, i) => {
                      return (
                        <option key={`${name}${i}`} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </>
                )}

                {options2 && options2Header && (
                  <optgroup label={options2Header}>
                    {options2.map((option, i) => {
                      return (
                        <option key={`${name}${i}`} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </optgroup>
                )}
                {options2 && !options2Header && (
                  <>
                    {options2.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
              {errors && <span className="help is-danger">{errors[0]}</span>}
            </p>
            {addonText && (
              <p className="control">
                {/* eslint-disable-next-line  */}
                <a className="button is-static">{addonText}</a>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SelectInputRowRekenhulp.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.object),
};

export const SelectInputRow = ({
  name,
  label,
  onChange,
  placeholder,
  dataTarget,
  value,
  error: errors,
  options,
  optionsHeader,
  options2,
  options2Header,
  className,
  addonText,
  simpleTooltipText,
  onBlur,
}) => {
  if (errors && errors.lenth > 0) {
    className = className + " is-danger";
  }

  const Option = ({ option, i }) => {
    return (
      <option
        className={`${option.isObsolete ? "has-text-danger is-italic" : ""}`}
        value={option.id}
      >
        {`${option.name} ${option.isObsolete ? " verlopen" : ""}`}
      </option>
    );
  };

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label has-text-left-mobile has-text-weight-medium">
          {label}{" "}
          {simpleTooltipText && (
            <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
          )}
        </label>
      </div>
      <div className="field-body">
        <div className="field has-addons rev-project-input">
          <p className="control select rev-project-expandaddon">
            <select
              name={name}
              value={value || ""}
              onChange={onChange}
              onKeyUp={nextOnEnter}
              onBlur={onBlur}
              className={`form-control input ${className}`}
              data-target={dataTarget}
            >
              {placeholder && <option value="">{placeholder}</option>}
              {optionsHeader && (
                <optgroup label={optionsHeader}>
                  {options.map((option, i) => {
                    return <Option key={i} option={option} i={i} />;
                  })}
                </optgroup>
              )}
              {!optionsHeader && (
                <>
                  {options.map((option, i) => {
                    return <Option key={i} option={option} i={i} />;
                  })}
                </>
              )}

              {options2 && options2Header && (
                <optgroup label={options2Header}>
                  {options2.map((option, i) => {
                    return <Option key={i} option={option} i={i} />;
                  })}
                </optgroup>
              )}
              {options2 && !options2Header && (
                <>
                  {options2.map((option, i) => {
                    return <Option key={i} option={option} i={i} />;
                  })}
                </>
              )}
            </select>
            {errors && <span className="help is-danger">{errors[0]}</span>}
          </p>
          {addonText && (
            <p className="control">
              {/* eslint-disable-next-line  */}
              <a className="button is-static">{addonText}</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

SelectInputRow.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.object),
};

export const SelectInputRowSearch = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  error: errors,
  options,
  className,
}) => {
  if (errors && errors.lenth > 0) {
    className = className + " is-danger";
  }

  if (placeholder) {
    options.unshift({ name: placeholder });
  }

  const selectOptions = options.map((entry) => ({
    value: entry.id,
    label: entry.name,
  }));

  let selectedValue = "";
  if (value) {
    const index = selectOptions.findIndex((x) => x.value === value);
    selectedValue = selectOptions[index];
  }

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label has-text-left-mobile has-text-weight-medium">
          {label}
        </label>
      </div>
      <div className="field-body">
        <div className="field has-addons rev-custom-component-input">
          <div className="control rev-custom-component-expandaddon">
            <Select
              //name={name}
              defaultValue={selectedValue || ""}
              onChange={(opt) => {
                onChange({ target: { name: name, value: opt.value } });
              }}
              className={`form-control rev-search-select ${className}`}
              options={selectOptions}
            />

            {errors && <span className="help is-danger">{errors[0]}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

SelectInputRowSearch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.object),
};
