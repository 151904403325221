import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import {
  AnchorButtonPrimary,
  AnchorButtonSecondary,
  AnchorButton,
  SubmitButton,
} from "./Buttons";
import { RevTooltipSimple } from "./revTooltip";

export const FormButton = ({
  onClick,
  name,
  disabled,
  dataTarget,
  className,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">&nbsp;</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control">
            <AnchorButtonPrimary
              onClick={onClick}
              disabled={disabled}
              text={name}
              dataTarget={dataTarget}
              className={className}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export const FormButtonProject = ({
  onClick,
  name,
  disabled,
  dataTarget,
  className,
  simpleTooltipText,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label">
        {simpleTooltipText && (
          <RevTooltipSimple label={"i"} tooltipText={simpleTooltipText} />
        )}
      </div>

      <div className="field-body">
        <AnchorButtonPrimary
          onClick={onClick}
          disabled={disabled}
          text={name}
          dataTarget={dataTarget}
          className={className}
        />
      </div>
    </div>
  );
};

export const FloatingButtonWrapper = (props) => {
  return (
    <div
      className={`rev-responsive-background rev-responsive-opaque-background`}
    >
      <div className="container ">
        <div className={`is-horizontal `}>{props.children}</div>
      </div>
    </div>
  );
};

export const FloatingFormSaveButton = ({
  onClick,
  text,
  disabled,
  extraClassName,
  extraMessage,
  dataTarget,
  isTop,
  slide,
  slideBack,
  id,
}) => {
  return (
    <div
      id={id}
      className={`rev-responsive-background rev-responsive-opaque-background
      ${isTop ? "rev-responsive-background-top" : ""} 
      ${slide ? "rev-responsive-slide-up" : ""} 
      ${slideBack ? "rev-responsive-slide-back-down" : ""} 
      `}
    >
      <div className="container">
        <div className={`field is-horizontal is-pulled-right`}>
          <AnchorButton
            onClick={onClick}
            disabled={disabled}
            text={
              <>
                <FontAwesomeIcon icon={faSave} />
                &nbsp;
                {text}
              </>
            }
            className={`button is-medium is-primary rev-button-size ${extraClassName}`}
            dataTarget={dataTarget}
          />
          {extraMessage && (
            <div className={`field-label is-normal`}>
              <span className={`label`}>{extraMessage}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const FloatingSubmitButton = ({
  text,
  extraClassName,
  opaqueBackground,
  isTop,
  isRight,
}) => {
  return (
    <div
      className={`rev-responsive-background 
    ${isTop ? "rev-responsive-background-top" : ""} 
    ${opaqueBackground ? "rev-responsive-opaque-background" : ""}`}
    >
      <div className="container ">
        <div
          className={`field is-horizontal  ${isRight ? "is-pulled-right" : ""}`}
        >
          <SubmitButton
            text={text}
            className={`button is-primary rev-button-size ${
              extraClassName ? extraClassName : ""
            } `}
          />
        </div>
      </div>
    </div>
  );
};

export const SubmitAndCancelButton = ({
  text,
  cancelText,
  extraClassName,
  extraCancelClassName,
  opaqueBackground,
  isTop,
  onClick,
  dataTarget,
}) => {
  return (
    <div
      className={`rev-responsive-background 
    ${isTop ? "rev-responsive-background-top" : ""} 
    ${opaqueBackground ? "rev-responsive-opaque-background" : ""}`}
    >
      <div className="container">
        <div className="columns is-mobile">
          <div className="column">
            <div className="is-pulled-left" style={{ width: "100%" }}>
              <AnchorButton
                onClick={onClick}
                text={cancelText}
                className={`button is-primary is-light rev-button-size is-pulled-left ${
                  extraCancelClassName ? extraCancelClassName : ""
                }`}
                dataTarget={dataTarget}
              />
            </div>
          </div>
          <div className="column">
            <div className={`field is-horizontal is-pulled-right`}>
              <SubmitButton
                text={text}
                className={`button is-primary rev-button-size is-pulled-right ${
                  extraClassName ? extraClassName : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DoubleFormButton = ({
  primaryOnClick,
  primaryText,
  primaryDisabled,
  primaryDataTarget,
  secondaryOnClick,
  secondaryText,
  secondaryDisabled,
  secondaryDataTarget,
  showWarning,
  warningText,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-body">
        <div className="field">
          <p className="control">
            <AnchorButtonPrimary
              onClick={primaryOnClick}
              disabled={primaryDisabled}
              text={primaryText}
              dataTarget={primaryDataTarget}
            />
            &nbsp;
            <AnchorButtonSecondary
              onClick={secondaryOnClick}
              disabled={secondaryDisabled}
              text={secondaryText}
              dataTarget={secondaryDataTarget}
            />
            {showWarning && (
              <>
                <br />
                <br />
                <label className="has-text-danger is-italic">
                  {warningText}
                </label>{" "}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
