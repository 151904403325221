import * as projectProps from "./projectConstants";
import { useState, useEffect } from "react";
import { downloadMjop } from "../../redux/slices/fileDownloadSlice";
import { SelectInputRow } from "../../components/common/SelectInput";
import { AnchorButtonPrimary } from "../../components/common/Buttons";
import {
  selectSystemYear,
  updateProject,
  selectProjectBouwdelen,
  selectCalculationYears,
  loadBouwdeelHandelingen,
  selectDecenniumCosts,
  getDecenniumCostAverages,
} from "../../redux/slices/userProjectSlice";
import { useSelector } from "react-redux";
import * as events from "../../custom-scripts/custom-javascript-events";
import { RadioButtonGroupProject } from "../../components/common/RadioButtonGroup";
import { GetShouldSaveForReports, SetCanSave } from "./ProjectSession";
import { ToggleDisplayButton } from "../../components/toggleDisplayButton";
import { DisplayLabel } from "../../components/common/DisplayLabel";
import { userHasProSubscription } from "../../redux/slices/userSlice";
import { NumberInput } from "../../components/common/NumberInput";
import { FormLoader } from "../../components/loader";

const ProjectPlanManager = ({ id, project, dispatcher }) => {
  var decenniumCosts = useSelector(selectDecenniumCosts);
  var bouwdelenCompleet = useSelector(selectProjectBouwdelen);
  const startYear = Number(useSelector(selectSystemYear));
  let bouwdelen;

  if (bouwdelenCompleet && project) {
    bouwdelen = Object.keys(bouwdelenCompleet)
      .filter(
        (key) =>
          bouwdelenCompleet[key].active &&
          (bouwdelenCompleet[key].soortId === 1 ||
            bouwdelenCompleet[key].soortId === 0)
      )
      .map((key) => ({
        id: bouwdelenCompleet[key].id,
        isLegacy: bouwdelenCompleet[key].isLegacy,
        amount: bouwdelenCompleet[key].amount
          ? bouwdelenCompleet[key].amount
          : project.bvo,
        referenceAmount: bouwdelenCompleet[key].referenceAmount,
        startYear: bouwdelenCompleet[key].startYear,
        livingUnits: bouwdelenCompleet[key].livingUnits,
      }));
  }
  const [mjopData, setMjopData] = useState();
  const [isWorking, setIsWorking] = useState(false);
  useEffect(() => {
    if (project) {
      if (mjopData) {
        if (isWorking) {
          setIsWorking(false);
          dispatcher(getDecenniumCostAverages(mjopData));
          const customEvent = new Event(events.eventToggleBoxOnclick);
          document.dispatchEvent(customEvent);
        }
      } else {
        setIsWorking(true);
        setMjopData({
          userGroupBeheerObjectId: project.id,
          startYear: startYear,
          period: 60,
          year: project.year,
          cycle: project.cycle,
          includeVat: project.includeVat,
          bouwdelen: bouwdelen,
          vtwCode: project.vtwCode,
          priceAdjustment:
            project.reference?.isCommercialProperty ===
            project.commercialProperty
              ? 0
              : project.potentialCommercialAdjustment,
        });
      }
    }
  }, [
    bouwdelen,
    dispatcher,
    mjopData,
    project,
    startYear,
    isWorking,
    decenniumCosts,
  ]);

  // retrieve session from selector

  const updateMjopSettings = (name, value) => {
    setMjopData((mjopData) => ({
      ...mjopData,
      [name]: value,
    }));
    setIsWorking(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    updateMjopSettings(name, value);
  };

  const downloadMjopOnSubmit = (e) => {
    setIsWorking(true);
    dispatcher(downloadMjop({ name: project.name, ...mjopData })).finally((x) =>
      setIsWorking(false)
    );
  };

  const yearSelection = useSelector(selectCalculationYears);

  const endYearSelection = startYear + 25 - Number(project?.bouwjaar);
  const yearBouwSelection = [];
  for (let i = 0; i < endYearSelection; i++) {
    yearBouwSelection.push({
      id: Number(project.bouwjaar) + i,
      name: Number(project.bouwjaar) + i,
    });
  }

  const onRadioButtonBoolChange = (e) => {
    const { name, value } = e.target;
    const setVal = value.toString() === "true";
    updateMjopSettings(name, setVal);
    dispatcher(updateProject({ id, name, value: setVal }));
  };

  const objectBouwdeelIds = Object.values(useSelector(selectProjectBouwdelen))
    .filter((bouwdeel) => bouwdeel.active)
    .map((bouwdeel) => bouwdeel.id);
  const canSaveBool = GetShouldSaveForReports() || !project || project.isNew;

  const onProjectChange = (e) => {
    const { name, value } = e.target;
    updateMjopSettings(name, value);
    SetCanSave(true);
    dispatcher(updateProject({ id, name, value }));
    if (name === projectProps.year) {
      dispatcher(
        loadBouwdeelHandelingen({
          year: value,
          objectBouwdeelIds,
          vtwCode: project.reference.vtwCode,
        })
      );
    }
  };

  const periodSelection = [];
  for (let i = 1; i <= 20; i++) {
    periodSelection.push({ id: i * 5, name: i * 5 });
  }

  const userIsProSubscription = useSelector(userHasProSubscription);
  return (
    <>
      {mjopData && (
        <section className="is-small pb-1">
          <div className="container rev-project-box-child-margin">
            <DisplayLabel
              label={"Referentie VTW-code"}
              text={project.reference.searchVtwCode}
              className="rev-is-borderless"
              isCompact={true}
            />
            <DisplayLabel
              label={"Object type"}
              text={project.reference.objectType}
              className="rev-is-borderless"
              isCompact={true}
            />
            <DisplayLabel
              label={"Aanmaakdatum"}
              text={project.createDate}
              className="rev-is-borderless"
              isCompact={true}
            />
          </div>
          <div className="container has-text-centered rev-project-box-child-margin">
            <div className="rev-folding-box">
              <ToggleDisplayButton
                dataTarget={"parametersToggleBox"}
                className={"rev-box-title"}
                isClosed={false}
                type={"parameters"}
              >
                {"Voorkeuren voor MJOP"}
              </ToggleDisplayButton>

              <div id="parametersToggleBox" className="rev-menu-open">
                <div className="rev-project-box-internal-padding">
                  <SelectInputRow
                    label="Start jaar"
                    name="startYear"
                    options={yearBouwSelection}
                    value={mjopData.startYear}
                    onChange={onChange}
                  />
                  <DisplayLabel label={"Bouwjaar"} text={project.bouwjaar} />
                  <SelectInputRow
                    label="Beschouwings periode"
                    name="period"
                    options={periodSelection}
                    value={mjopData.period}
                    onChange={onChange}
                    addonText="jaar"
                    simpleTooltipText="Periode waarover het onderhoudsplan wordt berekend."
                  />
                  <NumberInput
                    label={"Cyclus"}
                    value={project.cycle}
                    name={projectProps.cycle}
                    onChange={onProjectChange}
                    onBlur={onChange}
                    simpleTooltipText="Standaard is het uitpondmodel ingesteld met een cyclus tot en met 25 jaar. In dit geval worden de onderhoudselementen met een cyclus hoger dan 25 jaar niet meegenomen in de calculaties. Voor doorexploitatie vult u een waarde in tussen 26 en 99 en worden de daarbij behorende onderhoudselementen wel meegenomen."
                    noDecimals={true}
                  />

                  <SelectInputRow
                    label="Prijspeil"
                    name={projectProps.year}
                    options={yearSelection}
                    onChange={onProjectChange}
                    value={project.year}
                    addonText={"jaar"}
                    simpleTooltipText="Prijzen worden berekend met het geselecteerde peiljaar."
                  />

                  <RadioButtonGroupProject
                    label="BTW"
                    buttons={[
                      {
                        text: "Geen",
                        value: false,
                        checked: !project.includeVat,
                      },
                      {
                        text: "Wel",
                        value: true,
                        checked: project.includeVat,
                      },
                    ]}
                    name={projectProps.includeVat}
                    value={project.includeVat}
                    onChange={onRadioButtonBoolChange}
                    isBool={true}
                    simpleTooltipText="Met deze selectie wordt aangegeven of btw wel of niet bij de kosten mee berekend wordt"
                  />

                  <hr />

                  {userIsProSubscription && isWorking && <FormLoader />}
                  {userIsProSubscription && !isWorking && (
                    <>
                      <AnchorButtonPrimary
                        text="Download MJOP (Excel)"
                        onClick={downloadMjopOnSubmit}
                        disabled={canSaveBool}
                      />
                      <>
                        {canSaveBool && (
                          <>
                            <br />
                            <br />
                            <label className="has-text-danger is-italic">
                              {
                                "Sla het project eerst op, zie de Opslaan-knop onderaan de pagina"
                              }
                            </label>
                          </>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="rev-folding-box">
              <ToggleDisplayButton
                dataTarget={"decenniumToggleBox"}
                className={"rev-box-title"}
                isClosed={false}
                type={"decenniumToggleBox"}
              >
                {"Overzicht decennium kosten"}
              </ToggleDisplayButton>

              <div id="decenniumToggleBox" className="rev-menu-open">
                <div className="rev-project-box-internal-padding">
                  <table className="table rev-table-center is-striped is-hoverable">
                    <thead>
                      <tr className="has-background-primary">
                        <th className="has-text-white-bis has-text-right">
                          Periode
                        </th>
                        <th className="has-text-white-bis has-text-right">
                          Jaren
                        </th>
                        <th className="has-text-white-bis has-text-right">
                          Gemiddelde kosten per jaar
                        </th>

                        <th className="has-text-white-bis has-text-right">
                          % van totaal kosten per jaar
                        </th>
                      </tr>
                    </thead>
                    {decenniumCosts && decenniumCosts.length > 0 && (
                      <tbody>
                        {decenniumCosts.map((decennium, i) => {
                          return (
                            <tr key={i}>
                              <td>{decennium.period}</td>
                              <td>{decennium.years}</td>

                              <td>{decennium.yearlyAverage}</td>
                              <td>{decennium.percentageOfPeriodAverage}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProjectPlanManager;
